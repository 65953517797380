(function($){

	let slider = $('.fc-news__slider');

	if(!slider.length){
		return;
	}

	slider.each(function(){
		initSlider($(this));
	})

	function initSlider(el){

		let slider = el;
		let options = getSliderOptions();

		slider.owlCarousel(options);
	}

	function getSliderOptions(){

		let options = {
			'items'			: 1,
			'loop'			: true,	    	
	    	'smartSpeed'	: 300,
	    	'nav'			: true,
	    	'dots'			: true,
	    	'responsive'	: {
			    0 : {
			        'items' : 1,
			        'stagePadding'	: 60,
			        'margin'		: 20,
			        'center'		: true,
			    },
			    450 : {
			    	'items' : 1,
			    	'stagePadding'	: 60,
			        'margin'		: 30,
			        'center'		: true,
			    },
			    600 : {
			        'items' : 2,
			        'stagePadding'	: 60,
			        'margin'		: 30,
			        'center'		: true,
			    },
			    1024 : {
			        'items' : 3,
			        'stagePadding'	: 60,
			        'margin'		: 30,
			        'center'		: true,
			    },
			    1600 : {
			        'items' : 5,
			        'stagePadding'	: 60,
			        'margin'		: 30,
			        'center'		: true,
			    }
			},
			onInitialized: moveDots
		}

		return options;
	}

	function moveDots(event){
		let nextArrow = slider.find('.owl-next');
		let dots = slider.find('.owl-dots');

		if(!nextArrow.length || !dots.length){
			return false;
		}

		dots.insertBefore(nextArrow);
		nextArrow.before(dots);
	}

})(jQuery)