(function($){

	let triggers = $('.sh-menu-trigger');
	let menu = $('.mm');

	let links = $('.mm-main-menu__item--has-submenu > a');
	let containers = $('.mm-submenu-container');
	

	triggers.click(function(e){
		e.preventDefault();
		menu.toggleClass('js-visible');
		$('body').toggleClass('js-no-scroll');
	})

	links.click(function(e){
		e.preventDefault();

		if($(this).hasClass('js-active')){
			closeSubmenu($(this));
		}else{
			openSubmenu($(this));
		}
	})

	function openSubmenu(link){

		links.removeClass('js-active');
		containers.css('height','0');

		let container = link.next('.mm-submenu-container');
		let submenuHeight = container.find('.mm-submenu').outerHeight();

		container.css({
			'height' : submenuHeight + 'px'
		})

		link.addClass('js-active');
	}

	function closeSubmenu(link){
		let container = link.next('.mm-submenu-container').css('height','0');
		link.removeClass('js-active');
	}

})(jQuery)