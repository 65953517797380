(function($){

	let jqFilters = $('#filters');

	if(!jqFilters.length){ return }


	/*******

	VARIABLES

	*******/

	// Formularios
	let filters = document.getElementById('filters');
	let search = $('#search-form');
	let orderby = $('.filters-navbar__orderby');

	// Filtros
	let parentFilters = jqFilters.find('.filter--has-children');
	let containers = jqFilters.find('.filters__subcategory');
	let checkboxes = jqFilters.find('input[type="checkbox"]');
	let filtersReset = jqFilters.find('[type="reset"]');

	// Orderby
	let triggerOrderby = $('.js-trigger-dropdown-orderby');
	let optionsOrderby = $('.filters-navbar__orderby-item');

	// Caja de resultados
	let results = $('#results');

	/*******

	EVENTOS

	*******/

	jqFilters.on('reset',function(e){
		resetForm();
	})

	parentFilters.on('click',function(e){
		e.preventDefault();
		toggleSubCategory($(this));
	})

	checkboxes.on('change',function(e){
		updateSelectedFilter($(this));
	});

	triggerOrderby.on('click',function(e){
		e.preventDefault();
		toggleOrderby();
	})

	optionsOrderby.on('click',function(e){
		e.preventDefault();
		setOrderby($(this));
		submitAjaxRequest()
	})

	jqFilters.on('submit',function(e){
		e.preventDefault()
		submitAjaxRequest()
	})

	search.on('submit',function(e){
		e.preventDefault()
		submitAjaxRequest()
	})


	/*******

	FUNCIONES

	*******/

	function submitAjaxRequest(){
		let q = search.find('#search').val();
		let o = orderby.find('.js-orderby-is-selected a').data('value');
		let f = jqFilters.serialize();

		let request = $.ajax({
			url			: 'ajax-recursos.php',
			method		: 'POST',
			data		: f + '&q=' + q + '&o=' + o,
			beforeSend 	: toggleAjaxClass
		});

		request.done(function(response){
			results.html(response);
			toggleAjaxClass();	
		})

		request.fail(function(jqXHR, textStatus){
			console.log('Error petición ajax: ', textStatus)
		})

		function toggleAjaxClass(){
			results.toggleClass('js-ajax-request-sent')
		}
	}


	function toggleOrderby(){
		triggerOrderby.toggleClass('js-trigger-is-active');
		$('.filters-navbar__dropdown').toggleClass('js-dropdown-orderby-is-open')
	}

	function setOrderby(option){
		if(option.hasClass('js-orderby-is-selected')){
			return;
		}

		optionsOrderby.removeClass('js-orderby-is-selected');
		option.addClass('js-orderby-is-selected')
		toggleOrderby();
	}

	function toggleSubCategory(trigger){
		let container = trigger.next('.filters__subcategory');

		if(container.hasClass('js-is-expanded')){
			container.css('height','0px');
		}else{
			let containerHeight = container.find('.filters__subcategory-inner').outerHeight();
			container.css('height',containerHeight+'px');
		}

		container.toggleClass('js-is-expanded');
		trigger.toggleClass('js-is-active');
	}

	function updateSelectedFilter(checkbox){
		checkbox.closest('.filter').toggleClass('filter--is-selected');
		let group = checkbox.closest('.filters__group');
		if(group){
			let nrCheckedBoxes = group.find('input[type="checkbox"]:checked').length;
			group.find('.filter__counter').text('('+ nrCheckedBoxes +')')
		}

		updateSelectedFilterList()
	}

	function updateSelectedFilterList() {
		let selectedFilters = jqFilters.find('.filter--is-selected label');
		console.log(selectedFilters)
	}


	function resetForm(){
		filters.reset();

		parentFilters.removeClass('js-is-active')
		containers.removeClass('js-is-expanded')
		containers.each(function(i){
			$(this).css('height','0');
		})
		$('.filter__counter').text('');
	}



})(jQuery)