(function($){

	let expands = $('.js-expand')

	if(!expands.length){ return }

	expands.each(function(){
		initExpand($(this));
	})

	function initExpand(expand){
		let trigger = expand.find('.js-expand-trigger');
		let outer = expand.find('.js-expand-outer');
		let inner = expand.find('.js-expand-inner');

		outer.css({
			'height' :'0px',
			'overflow': 'hidden',
			'transition' : '.3s ease-in-out height'
		});

		trigger.on('click',function(e){
			e.preventDefault();
			if(trigger.hasClass('js-is-expanded')){
				outer.css('height','0px');
			}else{
				outer.css('height', inner.outerHeight() + 'px');
			}
			$(this).toggleClass('js-is-expanded')
		})
	}



})(jQuery)