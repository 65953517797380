(function($){
	
	let bgSections = $('.section-special-bg');

	if(!bgSections.length){ return }

	bgSections.each(function(i){
		initSection($(this));
	})

	function initSection(section){
		let bg = section.find('.section-special-bg__background');
		let primary = section.find('.section-special-bg__primary')
		let secondary = section.find('.section-special-bg__secondary')
		let posX = null;
		let posY = null;

		if(!primary.length){ return }

		getPositions();
		$(window).smartresize(function() {
			getPositions();	
		})

		function getPositions(){

			if( !window.matchMedia("(min-width: 1024px)").matches ){
				bg.removeAttr('style');
				return;
			}

			posX = primary.offset().left + ( primary.outerWidth() / 2 );
			posY = secondary.first().outerHeight() + ( parseInt( section.css('padding-top')) * 2 );

			if(section.hasClass('section-special-bg--from-right')){
				bg.css({
					right: 0,
					left: posX + 'px',
					height: posY + 'px',
				})
			}else{
				bg.css({
					left : 0,
					right: 'auto',
					width: posX + 'px',
					height: posY + 'px',
				})
			}			
		}

	}



})(jQuery);