(function($){

	let header = $('.site-header__main');
	let topbarHeight = $('.site-header__topbar').outerHeight();

	$(window).scroll(function(e){

		if($(window).scrollTop() > topbarHeight ){
			header.addClass('js-fixed');
		}else{
			header.removeClass('js-fixed');
		}

	});

})(jQuery)