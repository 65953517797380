(function($){

	let links = $('.sh-main-menu__item--has-submenu > a');

	links.on('click',function(e){
		e.preventDefault();

		if($(this).hasClass('js-menu-link-is-active')){

			$(this).removeClass('js-menu-link-is-active');
		
		}else{
			links.removeClass('js-menu-link-is-active');
			$(this).addClass('js-menu-link-is-active');
		}
	})

})(jQuery)