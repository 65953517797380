(function($){

	let drawer = $('#drawer-resources');

	if(!drawer.length){ return }

	let main = drawer.find('.drawer__inner-container');

	let trigger = null;
	let triggersAddToDrawer = $('.js-trigger-add-to-drawer');
	let triggerClear = $('.js-drawer-trigger-clear');
	let triggerDownloadAll = $('.js-drawer-trigger-download')
	let triggerLogoForm = $('#drawer-upload-logo-trigger');
	
	let template = document.querySelector('#drResourceTemplate');

	let resources = [];
	let resource = {
		'id' : null,
		'title' : null,
		'image' : null,
		'type' : null,
		'url' : null,
	}

	// EVENTOS

	triggersAddToDrawer.on('click',function(e){
		e.preventDefault();

		trigger = $(this);
		insertResource();
		drawer.addClass('js-drawer-is-visible');
	})

	$('body').on('click','.js-trigger-remove-from-drawer', function(e){
		e.preventDefault()
		trigger = $(this);
		removeResource();
	})

	triggerClear.on('click',function(e){
		e.preventDefault();
		removeAllResources();
	})

	triggerDownloadAll.on('click',function(e){
		e.preventDefault();
		downloadAllResources();
	})

	triggerLogoForm.on('click',function(e){
		e.preventDefault();
		toggleLogoForm();
	})


	// INICIO
	function setUpPage(){
		// Lee recursos de sesión y los almacena en variable global o la inicializa como array vacío
		readAllResourcesFromSession();

		if(resources.length === 0){ return }

		// Añade cada recurso de sesión al drawer y pone clase js-resource-is-selected a los que estén en la página
		resources.forEach( function(element, index) {
			resource = element;
			insertResourceIntoDrawer();
			addSelectedClass();
		});

		updateCounter();
	}

	// FUNCIONES CRUD PRINCIPALES

	function insertResource(){
		setResource();
		insertResourceIntoSession();
		insertResourceIntoDrawer();
		addSelectedClass();
		updateCounter();
	}

	function removeResource(){
		setResource();
		removeResourceFromSession();
		removeResourceFromDrawer();
		removeSelectedClass();
		updateCounter();
	}

	function readAllResourcesFromSession(){
		resources = JSON.parse(sessionStorage.getItem('resources'));

		if( resources == null ){
			resources = []
		}
	}

	function removeAllResources(){
		$('.dr-resource').remove();
		$('.js-resource').removeClass('js-resource-is-selected');

		sessionStorage.clear()
		
		updateCounter();
	}

	function downloadAllResources(){
		let ids = [];
		$('.dr-resource').each(function(i){
			ids.push($(this).data('id'));
		})

		alert('Soy la funcion downloadAllResources. Quieres descargar recursos con id: ' + ids.toString() );

		removeAllResources();
	}


	// FUNCIONES SECUNDARIAS
	// Captura los datos básicos de un recurso
	function setResource(){
		let el = trigger.closest('.js-resource');

		resource.id = el.data('id');
		resource.title = el.data('title');
		resource.image = el.data('image');
		resource.type = el.data('type');
		resource.url = el.data('url');
	}

	// Añade los datos básicos de un recurso al template del drawer
	function setResourceTemplate(){
		template.content.querySelector('.dr-resource').setAttribute('data-id',resource.id);
		template.content.querySelector('.dr-resource__image').setAttribute('src',resource.image);
		template.content.querySelector('.dr-resource__title').textContent = resource.title;
		template.content.querySelector('.dr-resource__type').textContent = resource.type;
	}

	// Abre Sesión:Recursos y añade un nuevo recurso
	function insertResourceIntoSession(){
		if(!resource){ return false; }
		readAllResourcesFromSession();
		resources.push(resource)
		sessionStorage.setItem('resources', JSON.stringify(resources));
	}

	// Añade el template cargado de datos al drawer
	function insertResourceIntoDrawer(){
		setResourceTemplate();
		let clone = document.importNode(template.content, true);  		
		main[0].appendChild(clone);
	}


	function addSelectedClass(){
		let r = '.js-resource[data-id="'+ resource.id +'"]';
		$(r).addClass('js-resource-is-selected');
	}

	function removeResourceFromSession(){
		readAllResourcesFromSession();
		let filtered = resources.filter(function(el) { return el.id != resource.id; });
		sessionStorage.setItem('resources', JSON.stringify(filtered));
	}

	function removeResourceFromDrawer(){
		let el = '.dr-resource[data-id="'+ resource.id +'"]';
		$(el).remove();
	}

	function removeSelectedClass(){
		let el = '.js-resource[data-id="'+ resource.id +'"]';
		$(el).removeClass('js-resource-is-selected');
	}

	function updateCounter(){
		main.attr('data-counter',$('.dr-resource').length );
	}

	function toggleLogoForm(){
		let topContainer = $('.drawer__logo-form-container');
		let containerHeight;

		if(topContainer.hasClass('js-is-visible')){
			containerHeight = '0'
		}else{
			containerHeight = $('.logo-form-container').outerHeight();
		}

		topContainer.css({
			'height' : containerHeight + 'px'
		}).toggleClass('js-is-visible');		
	}


	// Función inicial
	setUpPage();


})(jQuery)