(function($){

let appTabs = $('.js-tabs')

if(!appTabs.length){
	return;
}

appTabs.each(function(){
	initTabs($(this));
})

function initTabs(appTabs){

	let tabs = appTabs.find('.js-tabs__tab');
	let panels = appTabs.find('.js-tabs__panel');

	tabs.on('click.tabs', function(e){
		e.preventDefault();

		if( $(this).hasClass('js-active') ){
			return;
		}

		tabs.removeClass('js-active');
		$(this).addClass('js-active');

		let activeTab = tabs.index( $(this) );

		panels.removeClass('js-visible');
		panels.eq(activeTab).addClass('js-visible');
	});

}






})(jQuery)