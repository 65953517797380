// https://github.com/jestrux/jquery-mini-event-calendar
(function($){

	let calendar = $("#calendar");

	if(!calendar.length){ return }

	initCalendar();
	$(window).smartresize(function() {
		initCalendar();	
	});

	function initCalendar(){
		if(window.matchMedia("(min-width: 768px)").matches){
			$("#calendar").MEC({
				from_monday: true,
				events: FLCEvents
			});
			return;
		}
	}


})(jQuery);