/*
Adaptado de: https://css-tricks.com/jquery-magicline-navigation/
*/

$(function() {

    var $el, leftPos, newWidth,
        $mainNav = $("#sh-main-menu__list");
    
    $mainNav.append("<li id='magic-line'></li>");
    var $magicLine = $("#magic-line");
    
    $magicLine
        .width($(".sh-main-menu__item--current").width())
        .css("left", $(".sh-main-menu__item--current > a").position().left)
        .data("origLeft", $magicLine.position().left)
        .data("origWidth", $magicLine.width());
        
    $("#sh-main-menu__list > li").hover(function() {
        $el = $(this).find('a:first-child');
        leftPos = $el.position().left;
        newWidth = $el.width();
        $magicLine.stop().animate({
            left: leftPos,
            width: newWidth
        });
    }, function() {
        $magicLine.stop().animate({
            left: $magicLine.data("origLeft"),
            width: $magicLine.data("origWidth")
        });    
    });
});