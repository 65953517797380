(function($){

	let drawers = $('.drawer');
	if(!drawers.length){ return }

	let triggers = $('.js-trigger-drawer');

	// EVENTOS

	triggers.on('click',function(e){
		e.preventDefault();
		let drawer = $( $(this).attr('href') );
		drawer.toggleClass('js-drawer-is-visible');
	})

})(jQuery);