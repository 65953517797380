/************************

HELPERS:
- modernizr
- smartresize

****************************/
// @codekit-prepend "06_js/_modernizr.js";
// @codekit-prepend "06_js/_resizing.js";


/************************

FUNCIONES DEL HEADER

****************************/
/* Función que posiciona como fija la cabecera
cuando se hace scroll */
// @codekit-prepend "06_js/site-header/_scroll.js";

/* Función que controla el movimiento de la línea
verde del menú principal */
// @codekit-prepend "06_js/site-header/_magic-line.js";

/* Función que controla los submenús del menú principal */
// @codekit-prepend "06_js/site-header/_submenu.js";

/* Función que controla el menú mobile */
// @codekit-prepend "06_js/site-header/_mobile-menu.js";



/************************

SLIDERS
Se basan en el plugin jquery
owl-carousel (ver la carpeta
js/vendor)

****************************/
/* Slider de noticias en la home */
// @codekit-prepend "06_js/_slider--fc-news.js";

/* Slider de recursos relacionados en página recurso.php */
// @codekit-prepend "06_js/_slider--rel-resources.js";

/************************

LAYOUT
Posiciona correctamente el background
verde de las secciones con special-background

****************************/
// @codekit-prepend "06_js/_section-special-bg.js";


/************************

VARIOS

****************************/
/* Tabs de recursos destacados en la home */
// @codekit-prepend "06_js/_tabs.js";

/* Tabs de recursos destacados en la home */
// @codekit-prepend "06_js/_expand.js";

/* Calendario de Eventos en la home. Hace uso del
plugin jquery calendar.js (ver js/vendor/calendar) */
// @codekit-prepend "06_js/_calendar.js";


/************************

DRAWER
- función genérica para apertura de los dos drawers
(recursos seleccionados y filtros de búsqueda)
- Selección y eliminación de recursos destacados
- Subida de logos

****************************/

// @codekit-prepend "06_js/_drawer.js";
// @codekit-prepend "06_js/_drawer-resources.js";
// @codekit-prepend "03_components/_forms_custom-file-inputs/_component.js";



/************************

AJAX
Funcionamiento de los elementos relacionado con las
búsquedas de recursos: filtros, orderby
Consulta ajax

****************************/
// @codekit-prepend "06_js/_ajax-resources.js";

